<template>
	<div>
		<jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
			<div>
				<el-form :model="diaform" ref="diaform" :rules="rules">
					<el-form-item label="sim卡号:" prop="simNo" :label-width="formLabelWidth">
						<el-input v-model="diaform.simNo"></el-input>
					</el-form-item>
					<!-- <el-form-item label="iccid:" prop="iccid" :label-width="formLabelWidth">
                        <el-input v-model="diaform.iccid"></el-input>
                    </el-form-item> -->
					<el-form-item label="运营商:" prop="operator" :label-width="formLabelWidth">
						<el-select v-model="diaform.operator" placeholder="请选择">
							<el-option v-for="item in yysoptions" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="套餐:" prop="combo" :label-width="formLabelWidth">
						<el-input v-model="diaform.combo"></el-input>
					</el-form-item>
					<el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
						<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="diaform.remark">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</jy-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 对话框类型
				type: "",
				title: "",

				diaform: {
					simNo: "",
					iccid: "",
					operator: "",
					combo: "",
					remark: "",
				},
				dialogVisible: false,
				formLabelWidth: "120px",
				yysoptions: [
					{
						value: "0",
						label: "电信",
					},
					{
						value: "1",
						label: "移动",
					},
					{
						value: "2",
						label: "联通",
					},
				],

				rules: {
					simNo: [
						{
							required: true,
							message: "请输入sim卡号",
							trigger: "blur",
						},
					],
				},
			};
		},
		watch: {
			dialogVisible(newview) {
				if (!newview) {
					this.resetForm("diaform");
				}
			},
		},
		methods: {
			// 打开对话框
			init(option, type) {
				this.dialogVisible = true;
				this.type = type || "add";
				if (this.type == "update") {
					this.title = "编辑";
					this.$nextTick(() => {
						this.diaform = JSON.parse(JSON.stringify(option));
					});
				} else {
					this.title = "新增";
				}
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			cancel() {
				this.dialogVisible = false;
				console.log("cancel");
			},
			save() {
				this.$refs.diaform.validate((valid) => {
					if (valid) {
						let url = "";
						if (this.type == "add") {
							url = "/baseinfosimcard/save";
						} else {
							url = "/baseinfosimcard/update";
						}
						let option = this.diaform;
						this.$http.post(url, option).then((res) => {
							this.$message({
								message: res.msg,
								type: "success",
							});
							this.dialogVisible = false;
							this.$emit("updateList");
						});
					} else {
						return false;
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
</style>